import * as React from 'react'

import { Box, Container, Grid, useMediaQuery } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { LandingCtaButton, Subtitle, Title } from 'shared/components'
import { isLoggedIn } from 'shared/services'

import type { Theme } from '@mui/material'

const TextSection = () => (
  <Grid
    item
    xs={12}
    md={6}
    paddingBottom={3}
  >
    <Title
      mainTitle
      colorful
      fontWeight={400}
      paddingBottom={4}
    >
      Tu billetera en bitcoins y criptomonedas
    </Title>
    <Subtitle
      mainTitle
      color='text.primary'
      fontWeight={300}
      paddingBottom={5}
    >
      Protege tus ahorros, acumula recompensas semanales en tus criptomonedas y
      dólares, cámbiate a la nueva era financiera.
    </Subtitle>
    <LandingCtaButton
      id='hero-cta'
      size='large'
      sx={{ px: 4 }}
      isLoggedIn={isLoggedIn}
    />
  </Grid>
)

const ImageSection = () => (
  <Grid
    item
    xs={12}
    md={6}
  >
    <Box
      mx='auto'
      maxWidth='480px'
      position='relative'
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            paddingBottom: '95%',
            background: 'var(--color-mainTitleGradient)',
            borderRadius: '15%',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '98%',
            paddingBottom: '92.8%',
            bgcolor: 'background.default',
            borderRadius: '14.2%',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '98%',
            paddingBottom: '92.8%',
            bgcolor: 'action.hover',
            borderRadius: '14.1%',
          }}
        />
      </Box>
      <StaticImage
        src='../images/landing/header.png'
        alt='Aplicación y Tarjeta de Débito Sugarblock'
        loading='eager'
        placeholder='none'
      />
    </Box>
  </Grid>
)

const LandingTitle = () => (
  <Container
    maxWidth='lg'
    sx={{ py: 3 }}
  >
    <Grid
      container
      minHeight='min(85vh, 650px)'
      textAlign={useMediaQuery((theme: Theme) => theme.breakpoints.up('md')) ? 'left' : 'center'}
      justifyContent='center'
      alignItems='center'
      spacing={3}
      rowSpacing={4}
    >
      <TextSection />
      <ImageSection />
    </Grid>
  </Container>
)

export default LandingTitle
